.autocomplete-input {
  position: relative;
}

ul.options-list {
  display: flex;
  flex-direction: column;
  margin-top: -12px;
  border: 1px solid #dbdbdb;
  border-radius: 0 0 3px 3px;
  position: absolute;
  width: 100%;
  overflow: hidden;
  padding-left: 0px;
}

ul.options-list li {
  width: 100%;
  flex-wrap: wrap;
  background: white;
  margin: 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  padding-left: 10px;
}

ul.options-list li.highlighted {
  background: #f8f8f8
}
